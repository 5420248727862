<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="Customer Group">
        <CustomerGroup></CustomerGroup>
      </vs-tab>
      <vs-tab label="Distribution Channel">
        <DistributionChannel></DistributionChannel>
      </vs-tab>
      <vs-tab label="Payment Term">
        <PaymentTerm></PaymentTerm>
      </vs-tab>
      <vs-tab label="Pricing Group">
        <PricingGroup></PricingGroup>
      </vs-tab>
      <vs-tab label="Region">
        <Region></Region>
      </vs-tab>
      <vs-tab label="Risk Category">
        <RiskCategory></RiskCategory>
      </vs-tab>
      <vs-tab label="Customer">
        <Customer></Customer>
      </vs-tab>
      <vs-tab label="Customer Account">
        <CustomerAccount></CustomerAccount>
      </vs-tab>
      <vs-tab label="Customer Ship To">
        <CustomerShipTO></CustomerShipTO>
      </vs-tab>
      <vs-tab label="Country">
        <CustomerCountry></CustomerCountry>
      </vs-tab>
      <vs-tab label="Province">
        <CustomerProvince></CustomerProvince>
      </vs-tab>
      <vs-tab label="City">
        <CustomerCity></CustomerCity>
      </vs-tab>
      <vs-tab label="District">
        <CustomerDistrict></CustomerDistrict>
      </vs-tab>
      <vs-tab label="Sub District">
        <CustomerSubDistrict></CustomerSubDistrict>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import CustomerGroup from "./customer-group/index.vue";
import DistributionChannel from "./dis-chan/index.vue";
import PaymentTerm from "./payment-term/index.vue";
import PricingGroup from "./pricing-group/index.vue";
import Region from "./region/index.vue";
import RiskCategory from "./risk-category/index.vue";
import Customer from "./customer/index.vue";
import CustomerAccount from "./customer-account/index.vue";
import CustomerShipTO from "./customer-shipto/index.vue"
import CustomerCountry from "./customer-country/index.vue"
import CustomerProvince from "./customer-province/index.vue";
import CustomerCity from "./customer-city/index.vue"
import CustomerDistrict from "./customer-district/index.vue"
import CustomerSubDistrict from "./customer-sub-district/index.vue"

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    CustomerGroup,
    DistributionChannel,
    PaymentTerm,
    PricingGroup,
    Region,
    RiskCategory,
    Customer,
    CustomerAccount,
    CustomerShipTO,
    CustomerCountry,
    CustomerProvince,
    CustomerCity,
    CustomerDistrict,
    CustomerSubDistrict,
  },
  data() {
    return {
      title: "Customer",
    };
  },
};
</script>
